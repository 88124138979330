import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";
import TeacherGeneration from "../teacherGeneration/TeacherGeneration";
import AdminGenerationGet from "../adminGeneration/AdminGenerationGet";
import AdminSubcription from "../AdminSubcription";
import getFirebase from "../../../utils/firebase";
import { UserContext } from "../../../app";
import StudentGenerationGetDelete from "../../Teacher/studentGeneration/StudentGenerationGetDelete";
import ClassGenerationGetPut from "../../Teacher/classGeneration/ClassGenerationGetPut";
import TeacherGenerationPut from "../../Teacher/teacherGeneration/TeacherGenerationPut";

import MenuAdminList from "./MenuAdminList";

function AdminMenu() {
  const match = useRouteMatch();
  const firebase = getFirebase();
  // context
  const { useUser, isLoading } = useContext(UserContext);
  const [user, setUser] = useUser;
  const isTeacherLicence = user.isAdmin()
    ? user.isTeacherLicence()
    : user.admin.isTeacherLicence();

  const isTeacher = user.isTeacher();

  const logoutClicked = async () => {
    setUser(null);
    await firebase.auth().signOut();
  };

  if (!isLoading && user.isSignIn()) {
    console.log(user.isTeacher());
  }

  let linkTeacherAdmin;

  if (isTeacher && isTeacherLicence) {
    linkTeacherAdmin = (
      <>
        <Link className="adminMenuItem" to={`${match.path}/student`}>
          <li className="adminMenuList_list">Vos élèves</li>
        </Link>

        {/* <Link className='adminMenuItem' to={`${match.path}/addGroup`}>
         <li className='adminMenuList_list'>Ajouter des groupes</li>
       </Link> */}

        {!isTeacherLicence ? (
          <Link className="adminMenuItem" to={`${match.path}/addGroup`}>
            <li className="adminMenuList_list">Ajouter des groupes</li>
          </Link>
        ) : (
          ""
        )}
      </>
    );
  } else if (isTeacher) {
    linkTeacherAdmin = (
      <>
        <Link className="adminMenuItem" to={`${match.path}/student`}>
          <li className="adminMenuList_list">Vos groupes</li>
        </Link>

        <Link className="adminMenuItem" to={`${match.path}/addGroup`}>
          <li className="adminMenuList_list">Ajouter des groupes</li>
        </Link>
      </>
    );
  }

  return (
    <div className="adminMenu">
      <h3 className="adminMenu_title">Gestion du compte</h3>

      <ul className="adminMenu_menu adminMenuList">
        {MenuAdminList.map((item, index) => {
          return (
            <Link
              className={item.itemClassName}
              to={`${match.path}/${item.subPath}`}
            >
              <li className="adminMenuList_list" key={index}>
                {item.title}
              </li>
            </Link>
          );
        })}
        {linkTeacherAdmin}
        <a className="adminMenuItem" href="#" onClick={logoutClicked}>
          <li className="adminMenuList_list">Déconnexion</li>
        </a>
      </ul>
      <a
        href="https://www.editions-bordas.fr/recherche/type/P?text=holy%20owly"
        className="buttonAsset"
      >
        Guide pédagogique
      </a>
    </div>
  );
}

export default AdminMenu;
